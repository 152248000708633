<template>
  <div class="ma-4">
    <v-card>
      <v-toolbar
                      :color="$colors[0]"
                      dark>
                      <span class="Hatton pl-0">Nouvelle Vente professionnelle</span>
                  </v-toolbar>
    <!-- modale loading-->
    <v-row>
       <loadingComponent @closeDialog="dialog = $event" :messageDialogeProps="messageDialogeError" v-if="dialog" :dialogProps="dialog" :loadingProps="loading" :errorProps="error" />
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-form class="pa-4" ref="formSiret" lazy-validation>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="siretInfo.typeEnergie"
                @change="modiferTypeEnergie"
                row
              >
                <v-radio :color="$colors[0]" label="Electricité" value="ELEC"></v-radio>
                <v-radio :color="$colors[0]" label="Gaz" value="GAZ"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                ref="siret"
                v-model="siretInfo.numSiret"
                @input="changeSiret"
                outlined
                :rules="[required('un siret valide'), verifyLength()]"
                label="Siret"
                @keydown.enter.prevent="submitContinue"
              ></v-text-field>
            </v-col>

            <!-- v-if="siretFound == 1 || siretFound == 2" -->
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <v-btn
                depressed
                elevation="1"
                large
                :color="$colors[0]"
                @click="submitContinue"
                class="mt-1 ma-auto text-white"
                width="100%"
              >
                Valider
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    
    <v-alert dense text type="error" v-if="!statusCompteur.success">
      {{ this.statusCompteur.error }}
    </v-alert>
    <v-alert class="mt-1 mb-4"  dense text type="warning" v-if="!error_enedis && !statusCompteur.success">
      Attention, ce numéro de siret n'est pas enregistré dans notre base de données ou votre demande de cotation a été refusée
    </v-alert>
    
    <v-form class="ma-4" ref="formVente" lazy-validation>
      <v-row v-if="(listeCompteurs.length > 0)">
        <!-- info vente -->
        <v-col cols="12">
            <v-row>
            
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  :rules="[required('une raison sociale valide')]"
                  v-model="siretInfo.raison"
                  disabled
                  label="Raison sociale"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  :rules="[required('une fonction valide')]"
                  v-model="siretInfo.info.fonction"
                  label="Fonction"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  :rules="[required('un nom valide'),rules.isString]"
                  v-model="siretInfo.info.nom"
                  label="Nom"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  :rules="[required('un prenom valide'),rules.isString]"
                  v-model="siretInfo.info.prenom"
                  label="Prenom"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  :rules="[required('une adresse e-mail valide'),rules.emailRules]"
                  v-model="siretInfo.info.email"
                  label="Adresse e-mail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  :rules="[required('un numéro de téléphone valide'), rules.isNumber, rules.phoneLength]"
                  v-model="siretInfo.info.num_tel"
                  label="Numéro de téléphone"
                ></v-text-field>
              </v-col>
            </v-row>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row v-if="(listeCompteurs.length > 0)">
          <v-col class="mt-2" cols="12" sm="12" md="12" lg="12" xl="12">
              <span style='font-weight:bold;font-size:medium'>N° Compteur selectionné:</span>
          </v-col>
          <v-col class="pt-0" cols="12" sm="12" md="6" lg="6" xl="6">
            <v-combobox
              v-model="selectedCompteur" 
              :items="listeCompteurs" 
              :loading="isLoading"
              item-text="numCompteur"
              @change="changeCompteurSelected"
              hide-no-data 
              label="Autre compteur" 
              placeholder="Ajouter un compteur" 
              prepend-icon="mdi-account-search-outline"
              chips
              deletable-chips
              multiple
              return-object
                >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.numCompteur }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  >
                  (+{{ selectedCompteur.length -1 }} {{selectedCompteur.length -1 > 1 ? 'éléments' : "élément"}})
                </span>
              </template>
              <template v-slot:prepend-item>
                <v-btn @click="toggle">
                  <p v-if="selectedCompteur.length == listeCompteurs.length">Deselect All</p>
                  <p v-else>Select All</p>
                </v-btn>
              </template>
              <template v-slot:item="{ attrs, item, parent, selected }">
                <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                <v-list-item-content>
                  <v-list-item-title>{{ item.numCompteur }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.commerciale }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-col cols="12" sm="4" md="3" lg="3" xl="3" v-if="selectedCompteur.length>0">
                <v-btn class="text-white" :color="$colors[0]"  @click="appelleEnedis" >Récupérer la consommation</v-btn>
            </v-col>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="(listeCompteurs.length > 0)">
              <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="listCompteurResult.length>1">
                    <v-form class="ma-4" ref="defaultForm" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <strong>Appliqué comme valeurs par défaut pour toutes les ventes</strong>
                        </v-col>
                        <v-col cols="12" sm="3" md="2" lg="2" xl="2">
                          <v-text-field hide-details="auto" dense outlined label="Site par défaut" v-model="defaultTitre"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="2" lg="2" xl="2">
                          <v-text-field hide-details="auto" dense type="date" outlined label="DDF par défaut" v-model="defaultDateDebut"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="2" lg="2" xl="2">
                          <v-text-field hide-details="auto" dense outlined  type="date" 
                              label="DFF par défaut" v-model="defaultDateFin"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="2" lg="2" xl="2">
                          <v-text-field hide-details="auto" dense outlined suffix="€/MWh" type="number" step="0.01"
                              label="marge par défaut" min="2" max="25" v-model="defaultMarge"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="2" lg="2" xl="2">
                          <v-btn @click="appliquesDefaultValue">appliquées</v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="listCompteurResult.length>0 && siretInfo.typeEnergie=='GAZ'">
                    <b-table  :per-page="perPageGaz" :current-page="currentPageGaz" id="table-multisite-gaz" :busy="loadingTableHistorique" :fields="fieldhistoriqueDeCotationGAZ" :items="listCompteurResult" responsive bordered>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Chargement...</strong>
                        </div>
                      </template>
                      <template #cell(Site)='data'>
                        <v-text-field  hide-details="auto" dense outlined label="Site" :rules="[required('un titre valide')]" v-model="data.item.Site"></v-text-field>
                      </template>
                      <template #cell(numCompteur)='data'>
                          <small>{{data.item.numCompteur}}</small>
                      </template>
                      <template #cell(conso)='data'>
                          <v-text-field ref="consogaz" hide-details="auto" dense outlined suffix="Mwh" type="number" step="0.01" :rules="[required('une consommation valide'),consoMaxMin(data.item.tarif)]"
                            v-model="data.item.conso">
                          </v-text-field>
                      </template>
                      <template #cell(tarif)='data'>
                        <v-select
              
                          required
                          outlined
                          @change="handleTarifChange"
                          v-model="data.item.tarif"
                          :items="['T1', 'T2', 'T3', 'T4']"
                          hide-details="auto"
                          dense
                          :rules="[required('une Tarif valide')]"
                        ></v-select>
                      </template>
                      <template #cell(profile)='data'>
                        <v-select
                          v-if="data.item.tarif == 'T1'"
                          required
                          outlined
                          v-model="data.item.profile"
                          :items="listeProfileT1"
                          hide-details="auto"
                          dense
                          :rules="[required('une Profil valide')]"
                        ></v-select>
                        <v-select
                          v-if="data.item.tarif == 'T2'"
                          required
                          outlined
                          v-model="data.item.profile"
                          :items="listeProfileT2"
                          hide-details="auto"
                          dense
                          :rules="[required('une Profil valide')]"
                        ></v-select>
                        <v-select
                          
                          v-if="data.item.tarif == 'T3' || data.item.tarif == 'T4'"
                          required
                          outlined
                          v-model="data.item.profile"
                          :items="listeProfileT3"
                          hide-details="auto"
                          dense
                          :rules="[required('une Profil valide')]"
                        ></v-select>  
                      </template>
                      <template #cell(dateDebut)="data">
                        <v-text-field hide-details="auto" dense type="date"outlined :rules="[required('une DDF valide')]" v-model="data.item.dateDebut"></v-text-field>
                      </template>
                      <template #cell(dateFin)="data">
                        <v-text-field hide-details="auto" dense type="date" outlined
                          :rules="[required('une DFF valide')]"
                          v-model="data.item.dateFin">
                        </v-text-field>
                      </template>
                      <template #cell(marge)='data'>
                        <v-text-field hide-details="auto" dense outlined type="number" step="0.01"
                          :rules="[required('un marge valide')]"
                          v-model="data.item.marge">
                        </v-text-field>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPageGaz"
                      :total-rows="rowsGaz"
                      :per-page="perPageGaz">
                    </b-pagination>       
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="listCompteurResult.length>0 && siretInfo.typeEnergie=='ELEC'">
                      <b-table :per-page="perPage" 
                          :current-page="currentPage" 
                          id="tableau-multisite-elec" 
                          class="text-center" 
                          :busy="loadingTableHistorique"  
                          :fields="fieldhistoriqueDeCotationELEC" 
                          :items="listCompteurResult" 
                          responsive 
                          bordered>
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Chargement...</strong>
                          </div>
                        </template>
                        <template #cell(numCompteur)='data'>
                          <small>{{data.item.numCompteur}}</small>
                        </template>
                        <template #cell(Site)='data'>
                          <v-text-field hide-details="auto" dense outlined label="Site"
                            :rules="[required('un titre valide')]" v-model="data.item.Site">
                          </v-text-field>
                        </template>
                        <template #cell(conso)='data'>
                          <v-text-field hide-details="auto"  dense outlined suffix="Mwh" type="number" step="0.01"
                            :rules="[required('une Consommation valide')]"
                            disabled
                            v-model="data.item.conso">
                          </v-text-field>
                        </template>
                        <template #cell(dateDebut)="data">
                          <v-text-field hide-details="auto" dense type="date" outlined
                            :rules="[required('une DDF valide')]"
                            v-model="data.item.dateDebut"></v-text-field>
                        </template>
                        <template #cell(dateFin)="data">
                          <v-text-field hide-details="auto" dense type="date" outlined
                            :rules="[required('une DFF valide')]"
                            v-model="data.item.dateFin"></v-text-field>
                        </template>
                        <template #cell(marge)='data'>
                            <v-text-field hide-details="auto" dense outlined type="number" step="0.01"
                              :rules="[required('un marge valide')]"
                              v-model="data.item.marge"></v-text-field>
                        </template>
                        <template #cell(actions)="row">
                          <b-button @click="row.toggleDetails">
                            {{ row.detailsShowing ? '-' : '+' }}
                          </b-button>
                        </template>
                        <template #row-details="row">
                          <b-tr style="border: none !important; ">
                            <b-td  style="border: none !important; ">
                              <v-select
                                class="pa-4"
                                v-model="row.item.optionTarifaire"
                                @change="changeOptionTarifaire(row.item)"
                                :items="optionsTarifaires"
                                :rules="[required('Option Tarifaire')]"
                                :disabled="row.item.techniqueData.result!='notValide'"
                                outlined
                                dense
                                hide-details
                              />
                            </b-td>
                            <b-td  style="border: none !important; "  v-if="row.item.optionTarifaire == 'BTINFMU4' || row.item.optionTarifaire == 'BTINFCU4'">
                              <v-radio-group 
                                @change="changeOptionCalculeBtinf(row.item, row.item.parametreCompteur)" v-model="row.item.parametreCompteur" 
                                row
                                class="smallradio"
                                >
                                <v-radio label="C4" value="C5C4"></v-radio>
                                <v-radio label="HP/HC" value="C5HP"></v-radio>
                                <v-radio label="Base" value="C5BASE"></v-radio>
                              </v-radio-group>
                            </b-td>
                            <b-td  style="border: none !important; vertical-align: middle;">
                              <v-text-field hide-details="auto" dense type="number" outlined label="KVA"
                                :rules="[required('une KVA valide')]"
                                v-model="row.item.kva"></v-text-field>
                            </b-td>
                            <b-td  style="border: none !important;vertical-align: middle; ">
                              <v-text-field hide-details="auto" dense type="number" outlined v-if="row.item.parametreCompteur == 'C5BASE'"
                                :rules="[required('une CABASE valide')]" label="BASE (MWh)"
                                @input="calculeSommeCotation(row.item)"
                                v-model="row.item.CABASE"></v-text-field>
                              <v-text-field hide-details="auto" dense type="number" outlined v-if="row.item.parametreCompteur == 'C5HP'"
                                @input="calculeSommeCotation(row.item)" label="HP (MWh)"
                                :rules="[required('une CAHP valide')]"
                                v-model="row.item.CAHP"></v-text-field>
                              <v-text-field hide-details="auto" dense type="number" outlined 
                                v-if="row.item.parametreCompteur == 'C4' | 
                                  row.item.parametreCompteur == 'C3' |
                                  row.item.parametreCompteur == 'C5C4'"
                                @input="calculeSommeCotation(row.item)"
                                :rules="[required('une CAHPH valide')]" label="HPH (MWh)"
                                v-model="row.item.CAHPH"></v-text-field>
                            </b-td>
                            <b-td  style="border: none !important;vertical-align: middle; "v-if="row.item.parametreCompteur != 'C5BASE'">
                              <v-text-field hide-details="auto" dense type="number" outlined v-if="row.item.parametreCompteur == 'C5HP'"
                                @input="calculeSommeCotation(row.item)" label="HC (MWh)"
                                :rules="[required('une CAHC valide')]"
                                v-model="row.item.CAHC"></v-text-field>
                              <v-text-field hide-details="auto" dense type="number" outlined 
                                v-if="row.item.parametreCompteur == 'C4' | 
                                  row.item.parametreCompteur == 'C3' |
                                  row.item.parametreCompteur == 'C5C4'"
                                @input="calculeSommeCotation(row.item)"
                                :rules="[required('une CAHCH valide')]" label="HCH (MWh)"
                                v-model="row.item.CAHCH"></v-text-field>
                            </b-td>
                            <b-td  style="border: none !important; vertical-align: middle;"v-if="row.item.parametreCompteur == 'C4' | 
                                  row.item.parametreCompteur == 'C3' |
                                  row.item.parametreCompteur == 'C5C4'">
                              <v-text-field hide-details="auto" dense type="number" outlined 
                                @input="calculeSommeCotation(row.item)"
                                :rules="[required('une CAHPE valide')]" label="HPE (MWh)"
                                v-model="row.item.CAHPE"></v-text-field>
                            </b-td>
                            <b-td  style="border: none !important;vertical-align: middle; "v-if="row.item.parametreCompteur == 'C4' | 
                                  row.item.parametreCompteur == 'C3' |
                                  row.item.parametreCompteur == 'C5C4'">
                              <v-text-field hide-details="auto" dense type="number" outlined 
                                @input="calculeSommeCotation(row.item)"
                                :rules="[required('une CAHCE valide')]" label="HCE (MWh)"
                                v-model="row.item.CAHCE"></v-text-field>
                            </b-td>
                            <b-td  style="border: none !important; vertical-align: middle; "v-if="row.item.parametreCompteur == 'C3'">
                              <v-text-field hide-details="auto" dense type="number" outlined 
                                :rules="[required('une CAPointe valide')]"
                                @input="calculeSommeCotation(row.item)" label="Pointe (MWh)"
                                v-model="row.item.CAP"></v-text-field>
                            </b-td>
                          </b-tr>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage">
                      </b-pagination>       
                  </v-col>
              </v-row>
          </v-col>
      </v-row>
      
      <v-row v-if="listeCompteurs.length > 0 && consommationRecuperer==true">
          <v-col cols="12" sm="6" md="6" lg="6" xl="6" v-if="siretInfo.typeEnergie === 'ELEC'" >
            <v-divider></v-divider>
            <p style='font-weight:bold;font-size:medium'>Données relatives au fournisseur</p>
              <v-autocomplete
              ref="compteur"
              outlined
              :rules="[required('un Fournisseur valide')]"
              :items="listeFournisseursElec"
              v-model="siretInfo.fournisseur"
              @change="choisieFournisseur"
              label="Fournisseur"
              ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6" v-if="siretInfo.typeEnergie === 'GAZ'" >
            <v-divider></v-divider>
            <p style='font-weight:bold;font-size:medium'>Données relatives au fournisseur</p>
              <v-autocomplete
              ref="compteur"
              outlined
              :rules="[required('un Fournisseur valide')]"
              :items="listeFournisseursGaz"
              v-model="siretInfo.fournisseur"
              @change="choisieFournisseur"
              label="Fournisseur"
              ></v-autocomplete>
          </v-col>
          <v-col class="d-flex flex-row align-center" cols="12" sm="6" md="6" lg="6" xl="6">
            <v-radio-group
                hide-details
                v-model="typePayement"
                @change="modiferTypePayement"
                label="Paiement Par:"
                row
              >
                <v-radio :color="$colors[0]" label="RIB" value="RIB"></v-radio>
                <v-radio :color="$colors[0]" label="virement/prélèvement" value="virment"></v-radio>
            </v-radio-group>
          </v-col>
      </v-row>
      <v-row v-if="siretInfo.fournisseur=='Ekwateur'">
          <v-col cols="12" md="12" lg="12" xl="12"  v-if="siretInfo.fournisseur=='Ekwateur'">
          <v-card>
              <v-toolbar
                      :color="$colors[0]"
                      dark>
                      <span class="text-h6 pl-0">Informations d'Annexe 5</span>
                  </v-toolbar>
              <v-row class="ma-4">
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                      outlined
                      :rules="[required('un IBAN valide')]"
                      v-model="infoAnnexe.IBAN"
                      label="IBAN">
                      </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                      <v-text-field
                      outlined
                      :rules="[required('un nom de banque valide')]"
                      v-model="infoAnnexe.bank_name"
                      label="Nom de la banque">
                      </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                      <v-text-field
                      outlined
                      :rules="[required('un BIC valide')]"
                      v-model="infoAnnexe.bic"
                      label="BIC">
                      </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <v-btn :color='$colors[0]' class="white--text" @click="getBankName">
                      obtenir les infos de la banque<v-icon dense class="ml-2">mdi-bank</v-icon>
                      </v-btn>
                  </v-col>
              </v-row>
          </v-card>
          </v-col>
      </v-row>
      <div v-if="(selectedCompteur.length > 0) && consommationRecuperer==true">
          <!-- input file -->
          <inputFilePDF class='mt-10' :numberOfDuplicate="fileTypes.length" v-if="siretInfo.fournisseur!=null && siretInfo.fournisseur!=''" 
          @clearFileList="clearFileList"  @removeSplitPDF="removeSplitPDF" @sendPDFSplit="receivePDFSplit" :filePossible="fileTypes"/>
          <v-row >
              <v-col class="" cols="10" sm="5" md="4" lg="3" v-for="index in fileTypes" :key="index">
                      <div class="flex w-full h-screen items-center justify-center text-center">
                      <div class="p-1 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                          <h4>{{index}}</h4>
                          <input type="file" style="display: none;" :name="index" :id="index" 
                          class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange(index, null)" :ref="index" accept=".pdf,.eml,.msg" />

                          <label :for="index" class="block cursor-pointer">
                              <div :class="fileRequired?'requireddropzone':'dropzone'">
                              <v-icon x-large>cloud_upload </v-icon>
                                  <p>
                                      Déposez votre fichier ici <span><br>(ou)</span>
                                  </p>
                                  <p style="background-color: #e2e2e2;">Séléctionnez le fichier à importer</p>
                                  <p>Types de fichier supportées: pdf, eml</p>
                              </div>
                          </label>
                          
                          <ul class="mt-4" v-if="filelist[index]" v-cloak>
                              <li class="text-sm p-1" v-if="filelist[index][0] && filelist[index]!=='undefined'" >
                                  <a :href="filelist[index][0].url" target="_blank">{{filelist[index][0].name}}</a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </v-col>
          </v-row>
          <!-- commentaire -->
          <v-row class="mt-10" align="center" justify="center">
              <v-col cols="12" md="12" lg="12">
                  <v-textarea
                  solo
                  auto-grow
                  name="comment"
                  label="Commentaire"
                  v-model="siretInfo.comment"
                  ></v-textarea>
              </v-col>
          </v-row>
          <!-- button -->
          <v-row align="end" justify="end" class="mb-8">
              <!-- Validation forme -->
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                  <v-btn
                      depressed
                      large
                      text
                      color="error"
                      class="ma-auto"
                      width="100%"
                      :loading="false"
                      @click="reinitialiseVente"
                      >
                      Réinitialiser
                  </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                  <v-btn
                      depressed
                      elevation="1"
                      large
                      :color="$colors[0]"
                      class="ma-auto text-white"
                      width="100%"
                      :loading="false"
                      @click="submitVente"
                      >
                      Envoyer
                  </v-btn>
              </v-col>
          </v-row>
      </div>
    </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import loadingComponent from '../../components/ui/loading-component.vue'
import inputFilePDF from './inputFilePDF'
export default {
  name: 'ajouterVenteProMulti',
  components:{loadingComponent,inputFilePDF},

  data() {
    return {
              perPage: 3,
        currentPage: 1,
        perPageGaz: 3,
        currentPageGaz: 1,
      titleDialog:"Ajouter une vente professionnelle",
      //pourcentage
      pourcentage: 0,
      //if call consommation
      consommationRecuperer: false,
      ///multi site
      isLoading: false,
      valGlobale: 0,
      listeProfileT1: ["P011"],
      listeProfileT2: ["P012", "P013", "P014", "P015", "P016", "P017", "P018", "P019"],
      listeProfileT3: ["P013", "P014", "P015", "P016", "P017", "P018", "P019"],
      loadingTableHistorique: false,
      fieldhistoriqueDeCotationELEC:[
          {key:"numCompteur", label:"Compteur"},
          {key:"Site", label:"Site"},
          {key:"conso",label:"Consommation"},
          {key:"dateDebut", label:"Début de contrat"},
          {key: "dateFin", label:"Fin de contrat"},
          {key:"marge", label:"Marge"},
          {key:"actions", label:"détails"},
          
      ],
      fieldhistoriqueDeCotationGAZ:[
          {key:"numCompteur", label:"Compteur"},
          {key:"Site", label:"Site"},
          {key:"conso",label:"Consommation"},
          {key:"tarif",label:"tarif"},
          {key:"profile",label:"profile"},
          {key:"dateDebut", label:"Début de debut"},
          {key: "dateFin", label:"Fin de contrat"},
          {key:"marge", label:"marge"},
      ],
      selectedCompteur: [],
      listCompteurResult:[],
      

      //old data
      error_enedis: true,
      dialog: false,
      loading: false,
      error: false,
      messageDialogeError: '',
      messageDialogeSucces:'',
      typeUser: '',
      continueAdmin: false,
      typeEnergieLocked: false,

      statusCompteur: {
        success: true,
        error: '',
        loading: false,
      },
      statusSiren: {
        success: false,
        error: '',
        loading: false,
      },

      optionsTarifaires: [
        "BTINFCU4",
        "BTINFCUST",
        "BTINFMU4",
        "BTINFMUDT",
        "BTINFLU",
        "BTSUPCU4",
        "BTSUPLU4",
        "HTACU5",
        "HTACUPM5",
        "HTALU5",
        "HTALUPM5",
      ],
      listeCompteurs: [],
      lesFilesPossible: [],
      typePayement: "RIB",
      listeFournisseursElec: ['Engie', 'EDF', 'Dyneff'],
      listeFournisseursGaz: ['Butagaz', 'Eni'],
      listeSiret: [],

      fileTypes: [],
      typeFile: '',
      infoAnnexe:{
        nomDeSiteSurFacture: "",
        puissance:"",
        Nvoie:"",
        libelleVoie: "",
        codePostal: "",
        commune: "",
        bank_name: "",
        bic: "",
        IBAN: "",
      },
      siretInfo: {
        fileRequired: false,
        typeEnergie: 'ELEC',
        numSiren: '',
        numSiret: '',
        raison: '',
        numCompteur: '',
        compteur: [],
        comment: '',
        fournisseur: '',
        valeur: 0,
        duree: 0,
        dateDF: null,
        info: {
          nom: '',
          prenom: '',
          num_tel: '',
          email: '',
          fonction: ''
        },
      },
      fileRequired: false,
      filelist: {
          'CGV': [],
          'IBAN': [],
          'contrat': [],
          'RIB': [],
          'facture': [],
          'SEPA': [],
          'ACD': [],
      },
      chargerToutFichier: true,
      //default
      defaultTitre: "",
      defaultDateDebut: "",
      defaultDateFin: "",
      defaultMarge: "",
      //les rules du formulaire
      
      required(fieldName) {
          return (value) => ((""+value).length>0)||((""+value).length>0 && value >=0) || `Veuillez saisir ${fieldName}`
      },
      rules: {
        isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
        noWhiteSpace: value => !(/\s/.test(value)) || "Ce champ ne doit pas être constitué d'espace",
        
        isSiren: (v) =>
          v.length == 9 && v.match(/^[0-9]+$/) != null|| 'Le siren doit être un nombre de 9 chiffres',        
        required: (value) => !!value || 'Ce champ est obligatoire.',
        value: (val) => val>=0 || "Ce champ doit être supérieur ou égal à zéro",

        sirenLength: (v) =>
          v.length == 9 || 'Le siren doit comporter 9 chiffres',
        isNumber: (v) => v.match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de chiffres',
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 ||
          'Le numéro de téléphone est une suite de 10 chiffres',
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || 'Le numéro de téléphone est invalide',
        // margeMaxMin: (v) =>
        //   (v <= 10 && v >= 2) || 'La marge doit être comprise entre 2 et 10',

        emailRules: (v) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || "l'adresse mail est invalide",
      },      
      verifyLength() {
         return (value) => value.length == 14 || 'Le siret doit comporter 14 chiffres.'
      },
      siretAcceptNumber() {
        return this.numSiret.match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de nombres'
      },
      siretInList() {
        return (
          this.toutLesSiret.indexOf(this.numSiret) != -1 ||
          'Choisissez un siret à partir de la liste'
        )
      },
      consoMaxMin(typeCompteur) {
        return (v) => {
          if (typeCompteur == "T1") {
            if (v >= 0 && v < 6) {
              return true;
            } else {
              return "consomation pour un Tarif T1 doit être comprise entre 0 et 5.99";
            }
          } else if (typeCompteur == "T2") {
            if (v >= 3 && v <= 350) {
              return true;
            } else {
              return "consomation pour un Tarif T2 doit être comprise entre 3 et 350";
            }
          } else if (typeCompteur == "T3") {
            if (v >= 100 && v < 5000) {
              return true;
            } else {
              //250 4999.99
              return "consomation pour un Tarif T3 doit être comprise entre 100 et 4999.99";
            }
          } else if (typeCompteur == "T4") {
            if (v >= 5000) {
              return true;
            } else {
              return "consomation pour un Tarif T4 doit être supérieur ou égal à 5000";
            }
          }
        };
      },
      minDebutContrat: new Date().toISOString().substr(0, 10),
    }
  },
    watch: {
        selectedCompteur(newVal, oldVal){
            if(newVal.length != oldVal.length){
                var index = -1
                for (var i = 0; this.listCompteurResult.length; i++){
                    index = newVal.findIndex(x => x === this.listCompteurResult[i].numCompteur)
                    if (index == -1) { 
                        this.listCompteurResult.splice(i, 1);
                        break
                    }
                }
            }
        },
        listCompteurResult(val){
            for(var i=0; i<this.listCompteurResult.length; i++){
                this.listCompteurResult[i]['valeur'] = 0
            }
        }
    },
  methods: {
    
    calculeSommeCotation(donneDeCotation) {
      if (donneDeCotation.parametreCompteur == "C3") {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAP)) {
            pointe = parseFloat(donneDeCotation.CAP);
          } else {
            pointe = 0;
          }
        } catch (error) {
          pointe = 0;
        }
        donneDeCotation.HPH = hph
        donneDeCotation.HCH = hch
        donneDeCotation.HPE = hpe
        donneDeCotation.HCE = hce
        donneDeCotation.POINTE = pointe
        donneDeCotation.conso = hch + hph + hce + hpe + pointe;
        donneDeCotation.CAR = hch + hph + hce + hpe + pointe;
        donneDeCotation.consommationTotale = hch + hph + hce + hpe + pointe;
      } else if (donneDeCotation.parametreCompteur == "C4" || donneDeCotation.parametreCompteur == "C5C4") {
        var hch = 0;
        var hph = 0;
        var hce = 0;
        var hpe = 0;
        var pointe = 0;
        try {
          if (!isNaN(donneDeCotation.CAHCH)) {
            hch = parseFloat(donneDeCotation.CAHCH);
          } else {
            hch = 0;
          }
        } catch (error) {
          hch = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPH)) {
            hph = parseFloat(donneDeCotation.CAHPH);
          } else {
            hph = 0;
          }
        } catch (error) {
          hph = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHPE)) {
            hpe = parseFloat(donneDeCotation.CAHPE);
          } else {
            hpe = 0;
          }
        } catch (error) {
          hpe = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHCE)) {
            hce = parseFloat(donneDeCotation.CAHCE);
          } else {
            hce = 0;
          }
        } catch (error) {
          hce = 0;
        }
        donneDeCotation.HPH = hph
        donneDeCotation.HCH = hch
        donneDeCotation.HPE = hpe
        donneDeCotation.HCE = hce
        donneDeCotation.conso = hch + hph + hce + hpe;
        donneDeCotation.CAR = hch + hph + hce + hpe;
        donneDeCotation.consommationTotale = hch + hph + hce + hpe;
      } else if (donneDeCotation.parametreCompteur == "C5HP") {
        var hc = 0;
        var hp = 0;
        try {
          if (!isNaN(donneDeCotation.CAHC)) {
            hc = parseFloat(donneDeCotation.CAHC);
          } else {
            hc = 0;
          }
        } catch (error) {
          hc = 0;
        }
        try {
          if (!isNaN(donneDeCotation.CAHP)) {
            hp = parseFloat(donneDeCotation.CAHP);
          } else {
            hp = 0;
          }
        } catch (error) {
          hp = 0;
        }
        
        donneDeCotation.HP = hp
        donneDeCotation.HC = hc
        donneDeCotation.conso = hc + hp;
        donneDeCotation.CAR = hc + hp;
        donneDeCotation.consommationTotale = hc + hp;
      } else if (donneDeCotation.parametreCompteur == "C5BASE") {
        var base = 0;
        try {
          if (!isNaN(donneDeCotation.CABASE)) {
            base = parseFloat(donneDeCotation.CABASE);
          } else {
            base = 0;
          }
        } catch (error) {
          base = 0;
        }
        donneDeCotation.BASE = base;
        donneDeCotation.conso = base;
        donneDeCotation.CAR = base;
        donneDeCotation.consommationTotale = base;
      }
    },
    changeOptionCalculeBtinf(obj, newParam) {
        obj.parametreCompteur = newParam;
    },
    changeOptionTarifaire(obj) {
      var optionTarifaire = obj.optionTarifaire;
      if (optionTarifaire == "BTINFMUDT") {
        obj.parametreCompteur = "C5HP";
      }
      if (optionTarifaire == "BTINFLU" || optionTarifaire == "BTINFCUST") {
        obj.parametreCompteur = "C5BASE";
      }
      if (["BTSUPCU4", "BTSUPLU4"].includes(optionTarifaire)) {
        obj.parametreCompteur = "C4";
      }
      if(optionTarifaire == "BTINFCU4"){
        obj.parametreCompteur = "C5BASE";
        obj.typeTraitementCompteur = "complex";
      }
      if(optionTarifaire == "BTINFMU4"){
        obj.parametreCompteur = "C5HP";
        obj.typeTraitementCompteur = "complex";
      }
      if (["HTACU5", "HTACUPM5", "HTALU5", "HTALUPM5"].includes(optionTarifaire)) {
        obj.parametreCompteur = "C3";
        obj.typeTraitementCompteur = "simple";
      }
    },
    handleTarifChange() {
      this.$nextTick(() => {
        this.$refs.consogaz.validate();
      });
    },
    modiferTypePayement(){
      if(this.typePayement!=="RIB"){
        var index = this.lesFilesPossible[this.siretInfo.fournisseur].indexOf('RIB')
        if(index != -1){
          this.lesFilesPossible[this.siretInfo.fournisseur].splice(index, 1)
        }
      }else{
        var index = this.lesFilesPossible[this.siretInfo.fournisseur].indexOf('RIB')
        if(index == -1){
          this.lesFilesPossible[this.siretInfo.fournisseur].push("RIB")
        }

      }
    },
    appliquesDefaultValue(){
      if(this.$refs.defaultForm.validate()){
        for(var i=0; i<this.listCompteurResult.length; i++){
          if(this.defaultTitre != "" && this.defaultTitre != null){
            this.listCompteurResult[i]['Site'] = this.defaultTitre
          }
          if(this.defaultDateDebut != "" && this.defaultDateDebut != null){
            this.listCompteurResult[i]['dateDebut'] = this.defaultDateDebut
          }
          if(this.defaultDateFin != "" && this.defaultDateFin != null){
            this.listCompteurResult[i]['dateFin'] = this.defaultDateFin
          }
          if(this.defaultMarge != "" && this.defaultMarge != null){
            this.listCompteurResult[i]['marge'] = this.defaultMarge 
          }  
        }
      }
    },
    changeCompteurSelected(){
        this.consommationRecuperer = false
    },
    changeSiret(){
        this.selectedCompteur = []
        this.listCompteurResult= []
        this.listeCompteurs= []
        this.lesFilesPossible= []
        this.selectedCompteur = []
        this.consommationRecuperer = false
    },
    calculeConsommationGlobale(){
        this.consommationGlobale = this.vente.consommation
        for(var i=0; i<this.listCompteurResult.length; i++){
            this.consommationGlobale = this.consommationGlobale + this.listCompteurResult[i]['conso']
        }
    },
    async appelleEnedis(){
      this.loadingTableHistorique = true;
        this.titleDialog = "Recupération de la consommation"
        await this.getConsoAllCompteur(this.siretInfo.typeEnergie)
        this.loadingTableHistorique = false;
        this.dialog = false;
        this.error = false;
    },
    async getPourcentage(numCompteur){
        var formData = new FormData()
        formData.append('numCompteur', numCompteur)
        await axios({
            url:
            process.env.VUE_APP_URL_API_CLIENT +
            'getPourcentageCompte/',
            method: 'POST',
            data: formData,
        })
        .then((res) => {
            this.pourcentage = res.data.result
        }).catch((err)=>{
            this.pourcentage = 0
        })
    },
    async getConsoAllCompteur(typeEnergie){
        if(typeEnergie == "GAZ"){
            this.dialog=true
            this.loading=true
            this.title = "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!"
            this.messageDialoge="veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!"
            var formData = new FormData()
            formData.append('token', this.getToken('token'))
            formData.append('listCompteur', JSON.stringify(this.selectedCompteur))
            formData.append('dateDebut', "01/01/2024")
            formData.append('dateFin',  "31/12/2026")
            formData.append('siret',  this.siretInfo.numSiret)
            await this.getPourcentage(this.selectedCompteur[0])
            await axios({
                url: process.env.VUE_APP_URL_API_CLIENT + 'getAllCompteurConsoGaz/',
                method: 'POST',
                data: formData,
            }).then((res) => {
              
              if(res.data.result == false){
                this.listCompteurResult=[]
                this.consommationRecuperer = false
                this.error = true
                this.messageDialogeError = "nous n'arrivons pas à obtenir les informations relatives au Siret!"
              }else{
                this.listCompteurResult = res.data.result
                this.consommationRecuperer = true
                this.dialog=false
              }
            }).catch((err)=>{
                this.consommationRecuperer = false
                this.listCompteurResult=[]
                this.error = true
                this.messageDialogeError = "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!"
            })
        }else if(typeEnergie == "ELEC"){
            this.dialog=true
            this.loading=true
            this.title = "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!"
            this.messageDialoge="veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!"
            var formData = new FormData()
            formData.append('token', this.getToken('token'))
            formData.append('listCompteur', JSON.stringify(this.selectedCompteur))
            formData.append('dateDebut', "01/01/2024")
            formData.append('dateFin',  "31/12/2026")
            formData.append('siret',  this.siretInfo.numSiret)
            await this.getPourcentage(this.selectedCompteur[0])
            await axios({
                url: process.env.VUE_APP_URL_API_CLIENT + 'getAllCompteurConso/',
                method: 'POST',
                data: formData,
            }).then((res) => {
              if(res.data.result == false){
                this.listCompteurResult=[]
                this.consommationRecuperer = false
                this.error = true
                this.messageDialogeError = "nous n'arrivons pas à obtenir les informations relatives au Siret!"
              }else{
                this.listCompteurResult = res.data.result
                this.consommationRecuperer = true
                this.dialog=false
              }
            }).catch((err)=>{
                this.listCompteurResult=[]
                this.consommationRecuperer = false
                this.error = true
                this.messageDialogeError = "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!"
            })
        }
    },
    toggle(){
            if(this.selectedCompteur.length == this.listeCompteurs.length){
        this.selectedCompteur = []

      }else{
        this.selectedCompteur = [...this.listeCompteurs]
      }
    },
    supprimerCompteur(numComp){
        this.consommationRecuperer = false;
        var index = this.selectedCompteur.findIndex(x => x === numComp)
        if (index > -1) { 
            this.selectedCompteur.splice(index, 1);
        }
        index = this.listCompteurResult.findIndex(x => x.numCompteur === numComp)
        if (index > -1) { 
            this.listCompteurResult.splice(index, 1);
        }
        index = this.listCompteur.findIndex(x => x === numComp)
        if(index == -1){
            this.listCompteur.push(numComp)
        }
    },
    dateFormatSlach(date){
        return date.split('-').reverse().join('/')
    },
    dateFormatMinus(date){
        return date.split('/').reverse().join('-')
    },
    isValidDate(formatDate, minDate) {
        return (date) => {
            var tmpdate = this.dateFormatMinus(date)
            if (!date) {
                return 'Veuillez saisir une date.'
            }
            if (moment(tmpdate).isBefore(minDate)) {
                return `La date doit être supérieure  au ${this.dateFormatSlach(minDate)}.`
            }
            return true
        }
    },
    clearFileList(){
      for(const [key,value] of Object.entries(this.filelist)){
        this.filelist[key] = []
      }
    },
    removeSplitPDF(filePDF){
      for(const [key,value] of Object.entries(this.filelist)){
        if(value[0] != null && value[0].name == filePDF.name){
          this.remove(key)
        }
      }
    },
    receivePDFSplit({listFilePDF, listeIndex}){ 
      for(var j =0; j< this.fileTypes.length; j++){
        var index = listeIndex.findIndex(x => x === j)
        if (index == -1){
          this.onChange(this.fileTypes[j],null)
        }
      }
      for(var i =0; i< listeIndex.length; i++){
          this.onChange(this.fileTypes[listeIndex[i]],[listFilePDF[i]])
      }
      
    },
    getBankName(){
      if (this.infoAnnexe.IBAN != "" || this.infoAnnexe.IBAN != null){
        var formDonne = new FormData()
          formDonne.append('iban', this.infoAnnexe.IBAN)
        axios({
              url: process.env.VUE_APP_URL_API_CLIENT + 'getBankName/',
              method: 'POST',
              data: formDonne,
              
          }).then((res) => {
            if(res.data.result==true){
              this.infoAnnexe.bank_name = res.data.bank_name
              this.infoAnnexe.bic = res.data.bic
            }else{
              this.infoAnnexe.bank_name = ""
              this.infoAnnexe.bic = ""
              alert("Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît")
            }
          }).catch((err)=>{
              this.infoAnnexe.bank_name = ""
              this.infoAnnexe.bic = ""
              alert("Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît")
              console.log(err)
          })
      }else{
        return false;
      }
    },
    modiferTypeEnergie(){
      this.siretInfo.fournisseur=''
      this.siretInfo.numCompteur=''
      this.siretInfo.raison=''
      this.siretInfo.duree=''
      this.siretInfo.dateDF=''
      this.siretInfo.info.fonction=''
      this.listeCompteurs=[]
      this.continueAdmin = false
      this.typeEnergieLocked = false
       this.fileRequired = false
      this.filelist['contrat'] = []
      this.filelist['facture'] = []
      this.filelist['CGV'] = []
      this.filelist['RIB'] = []
      this.filelist['IBAN'] = []
      this.filelist['SEPA'] = []
      this.filelist['ACD'] = []
      this.chargerToutFichier = true
      this.fileTypes = []
      this.selectedCompteur = []
      this.listCompteurResult= []
      this.listeCompteurs= []
      this.lesFilesPossible= []
      this.consommationRecuperer = false
    },
    verifierQueToutLesFichierCharger(){
        for(var i =0; i< this.fileTypes.length; i++){
            if(this.filelist[this.fileTypes[i]].length==0){
                return false
            }
        }
        return true
    },
    reinitialiseVente() {
        this.selectedCompteur = []
        this.listCompteurResult= []
        this.lesFilesPossible= []
        this.selectedCompteur = []
        this.consommationRecuperer = false
      this.listeCompteurs = []
      this.listeSiret = []
      this.continueAdmin = false
      // this.listeFournisseursElec = []
      // this.listeFournisseursElec = []
      this.typeEnergieLocked = false
      this.statusCompteur.success = true
      this.statusCompteur.error = ''
      this.statusCompteur.loading = false

      this.statusSiren.success = false
      this.statusSiren.error = ''
      this.statusSiren.loading = false
       this.fileRequired = false
       
      this.siretInfo.raison=''
      this.siretInfo.duree=''
      this.siretInfo.dateDF=''
      this.siretInfo.info.fonction=''
      this.filelist['contrat'] = []
      this.filelist['facture'] = []
      this.filelist['CGV'] = []
      this.filelist['RIB'] = []
      this.filelist['IBAN'] = []
      this.filelist['SEPA'] = []
      this.filelist['ACD'] = []
      this.chargerToutFichier = true
    },
    async submitVente() {
        if(this.$refs.formVente.validate()){
            let self = this
            if (!this.$refs.formVente.validate()) return
            this.chargerToutFichier = this.verifierQueToutLesFichierCharger()
            if(this.chargerToutFichier == false) return
            self.dialog = true
            self.loading = true
            self.error = false
            self.messageDialogeError = ''
            self.messageDialogeSucces = ''
            var formData = new FormData()
            formData.append('infoAnnexe', JSON.stringify(this.infoAnnexe))
            formData.append('siret', this.siretInfo.numSiret)
            formData.append('type', this.siretInfo.typeEnergie)
            formData.append('nom', this.siretInfo.info.nom)
            formData.append('prenom', this.siretInfo.info.prenom)
            formData.append('email', this.siretInfo.info.email)
            formData.append('numTele', this.siretInfo.info.num_tel)
            formData.append('raison', this.siretInfo.raison)
            formData.append('status', 'en Attend')
            formData.append('commentaire', this.siretInfo.comment)
            formData.append('fonction', this.siretInfo.info.fonction)
            formData.append('fournisseur', this.siretInfo.fournisseur)
            formData.append('listeToutesVente',JSON.stringify(this.listCompteurResult))
            formData.append('token', this.getToken('token'))
            for(var i =0; i< this.fileTypes.length; i++){
                formData.append(this.fileTypes[i], this.filelist[this.fileTypes[i]][0])
            }
            axios({
                url: process.env.VUE_APP_URL_API_CLIENT + 'ajouterVenetePro/',
                method: 'POST',
                data: formData,
                
            }).then((res) => {
                if(res.data.result == true){
                    self.loading = false
                    self.error = false
                    self.messageDialogeError = 'Vente ajoutée avec succès !'
                }else if (res.data.result == false){
                    self.loading = false
                    self.error = true
                    self.messageDialogeError = "Il semble qu'il y ait un problème. Veuillez contacter un administrateur"
                }else if (res.data.result == 'IBAN'){
                    self.loading = false
                    self.error = true
                    self.messageDialogeError = "Vérifier le numéro IBAN!"
                }else if (res.data.result == 'Error'){
                    self.loading = false
                    self.error = true
                    self.messageDialogeError = "la clé de L'Api qui vérifie l'IBAN a expiré!"
                }else if(res.data.result == "permission"){
                  self.loading = false
                  self.error = true
                  self.messageDialogeError = 'Permission refusée'
                }
            }).catch((err)=>{
              self.dialog = false
              self.loading = false
              self.error = false
              self.messageDialogeSucces = ''
              self.messageDialogeError = ''
            })
        }
    },
    submitRechercheSiret() {
      this.$refs.formSiren.validate();
      //vide la liste des SIRET avant chaque recherche
      this.listeSiret = []
      //si le SIREN est saisi
      if (this.$refs.formSiren.validate()) {
        this.getAllSiret(this.siretInfo.numSiren)
      }
    },
    async submitContinue() {
      if (this.$refs.formSiret.validate()){
        this.dialog = true;
        this.loading = true;
        this.typeEnergieLocked = true
        await this.getInfoEtCompteur(
          this.siretInfo.numSiret,
          this.siretInfo.typeEnergie
        )
        this.loading = false;
        this.dialog = false;

      }
    },
    choisieFournisseur(){
        this.typePayement="RIB"
        this.fileTypes = this.lesFilesPossible[this.siretInfo.fournisseur]
        this.typeFile = this.fileTypes[0]
        for(var i = 0; i< this.fileTypes.length; i++){
            this.filelist[this.fileTypes[i]]=[]
        }
    },

    async getInfoEtCompteur(siret, typeEnergie) {  
        let self = this
        this.statusCompteur.success = true
        var formData = new FormData()
        formData.append('siret', siret)
        formData.append('token', this.getToken('token'))
        formData.append('type', typeEnergie)
        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT + 'getInfoEtCompteurVentePro/',
          method: 'POST',
          data: formData,
        }).then((res)=>{
            var data = JSON.parse(JSON.stringify(res.data))
            if(data.result == false){
              this.statusSiren.success = true
              this.statusSiren.loading = false
              self.statusCompteur.success = false
              this.statusCompteur.error = "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre demande de cotation a bien été acceptée"
              
            }else if(data.result == true){
                self.listeCompteurs = data.compteur
                self.siretInfo.info = data.info
                self.siretInfo.raison = data.info.raison
                self.siretInfo.info.fonction = data.info.fonction
                var formData1 = new FormData()
                formData1.append('type', typeEnergie)
                  axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getFournisseurAndFiles/',
                    method: 'POST',
                    data: formData1,
                    
                }).then((res)=>{
                    if(typeEnergie == "ELEC"){
                        this.listeFournisseursElec = res.data.fournisseur
                        this.lesFilesPossible = res.data.files
                    }else{
                        this.listeFournisseursGaz = res.data.fournisseur
                        this.lesFilesPossible = res.data.files
                    }
                    
                }).catch((err)=>{

                })
                
            }else if(data.result == 'permissionAdmin'){
              
              this.statusSiren.success = true
              this.statusSiren.loading = false
              self.statusCompteur.success = false
              this.statusCompteur.error = "Il n'y a pas de numéro de compteur associé à ce siret, merci de vérifier que votre demande de cotation a bien été acceptée"
            
            }else if(data.result == 'permission'){
              localStorage.removeItem('vendeurName')
              localStorage.removeItem('typeUser')
              localStorage.removeItem('token')
              this.$router.push('/login')
            }
            
        }).catch ((err) => {
            self.statusCompteur.success = false
            self.statusCompteur.error = err.message
        })
        
    },
    onChange(file, data) {
        if(data==null){
            this.filelist[file] = this.$refs[file][0].files
        }else{
            let blobPDF = new Blob([data[0]], { type: 'application/pdf' });
            data[0].url = window.URL.createObjectURL(blobPDF)
            this.filelist[file] = data;
        }
    },

    //files
    remove(file) {
          this.filelist[file]= []
    },
    dragover(event) {
        event.preventDefault();
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
            event.currentTarget.classList.remove('bg-gray-100');
            event.currentTarget.classList.add('bg-green-300');
        }
    },
    dragleave(event) {
        // Clean up
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
        let self = this
        event.preventDefault();
        var nameInput = event.currentTarget.querySelector('input').name
        if(event.dataTransfer.files.length>1){
            self.$refs.file.files=null;
        }else{
            var posExtension = event.dataTransfer.files[0].name.split('.').length-1;
            var extension = event.dataTransfer.files[0].name.split('.')[posExtension];
            if(extension != 'pdf' && extension != 'PDF' && extension != 'EML' && extension != 'eml'  && extension != 'MSG' && extension != 'msg'){
              self.$refs[nameInput].files=null;
            }else{
              
              self.$refs[nameInput].files = event.dataTransfer.files;
                self.onChange(nameInput, event.dataTransfer.files); // Trigger the onChange event manually
                // Clean up
                event.currentTarget.classList.add('bg-gray-100');
                event.currentTarget.classList.remove('bg-green-300');
            }
            
        }
    },
  },
  computed: {
      rows() {
        return this.listCompteurResult.length
      },
      rowsGaz() {
        return this.listCompteurResult.length
      }
    },
     created() {
            try {
                (this.$route.query.siret) ? this.siretInfo.numSiret = this.$route.query.siret : null;
            } catch (error) {
                console.log(error)
            }
      this.clearFileList();
    },
}
</script>
<style>
#tableau-multisite-elec.table td:first-child{
  vertical-align: middle;
}

#tableau-multisite-gaz.table td:first-child{
  vertical-align: middle;
}

  #tableau-multisite-elec.table thead th, #table-multisite-gaz.table thead th{
    background-color:#103A5E;
    color:white;
    font-weight:bold;
  }
</style>
<style scoped>
::v-deep .v-label{
  margin:0px; 
}

@media only screen and (max-width:400px){
    .title-container{
      font-size:large;
      margin-left:0px !important;
    }
  }
</style>
<style scoped lang="scss">
    .dropzone {
    border: 2px solid #90a4ae56;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    p {
        margin-bottom: 0;
        &:first-of-type {
        font-weight: 500;
        font-size: 1rem;
        color: #263238;
        span {
            color: gray;
        }
        }
        &:last-of-type {
        font-size: 0.8rem;
        }
    }
    }
    .requireddropzone {
    border: 2px dashed red;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    p {
        margin-bottom: 0;
        &:first-of-type {
        font-weight: 500;
        font-size: 1rem;
        color: #263238;
        span {
            color: gray;
        }
        }
        &:last-of-type {
        font-size: 0.8rem;
        }
    }
    }
